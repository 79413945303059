import React from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'

const PreviewCompatibleBackroundImage = ({ children, imageInfo }) => {
  const imageStyle = { borderRadius: '5px' }
  const { alt = 'FilOZ Homepage Hero', childImageSharp, image, url } = imageInfo

  if (!!image && !!image.childImageSharp) {
    return (
      <div>
        <BackgroundImage
            Tag="section"
            className="full-width-image margin-top-0"
            fluid={image.childImageSharp.fluid}
            alt={alt}
          >
            { children }
        </BackgroundImage>
      </div>
    )
  }

  if (!!childImageSharp) {
    return (
      <div>
        <BackgroundImage
          Tag="section"
          className="full-width-image margin-top-0"
          fluid={childImageSharp.fluid}
          alt={alt}
        >
          { children }
        </BackgroundImage>
      </div>
    )
  }

  if (!!url) {
    return (
      <div>
        <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${ url })`,
          backgroundBlendMode: 'overlay',
          backgroundPosition: `top left`,
        }}>
        { children }
      </div>
    </div>
    )
  }

  return null
}

PreviewCompatibleBackroundImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
  }),
}

export default PreviewCompatibleBackroundImage
