import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PreviewCompatibleBackroundImage from '../components/PreviewCompatibleBackgroundImage'

import Features from '../components/Features'
import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'


export const IndexPageTemplate = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      innerWidth: 0
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.setState({innerWidth: window.innerWidth});
    }
  }

  headerContents = (title, subheading, subheading2, subheading3) => {
    var firstInnerWidth = Math.round(this.state.innerWidth * 0.9);
    var secondInnerWidth = Math.round(this.state.innerWidth * 0.85);
    return (
      <div style={{
        background: 'linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))',
        paddingLeft: '20px'
      }}>
      <div className='columns'
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <div className="column is-offset-1">
          <h1 className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
              fontFamily:'Holtwood One SC',
            }}
          >
            {title}
          </h1>
        </div>
      </div>
      <div className='columns' style={{
          height: '100%',
          width: '100%',
        }}>
        <div className="column is-8 is-offset-1">
          <h3 className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              color: '#f6bf01',
              lineHeight: '1',
              padding: '0.25em',
              wordWrap: 'normal',
            }}
          >
            {subheading}
          </h3>
        </div>
      </div>
       <div className='columns' style={{
          height: '100%',
          width: '100%',
        }}>
         <div className="column is-8 is-offset-1">
           <h3
             className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
             style={{
               color: '#f6bf01',
               lineHeight: '1',
               padding: '0.25em',
             }}
           >
             {subheading2}
           </h3>
         </div>
       </div>
       <div className='columns' style={{
          height: '100%',
          width: '100%',
        }}>
         <div className="column is-8 is-offset-1">
           <h3
             className="is-size-6-mobile is-size-6-tablet is-size-4-widescreen"
             style={{
               color: 'white',
               lineHeight: '1',
               padding: '0.25em',
               fontStyle: 'italic'
             }}
           >
             {subheading3}
           </h3>
         </div>
       </div>
       <section className="section">
        <div className="container">
          <div className="content">
            <BlogRoll top={true} />
          </div>
        </div>
       </section>
       <div className='columns is-tablet' style={{
          height: '100%',
          width: '100%',
        }}>
         <div className="column is-10 is-offset-1">
           <iframe
             loading="lazy"
             height='470px'
             width={`${firstInnerWidth > 500 ? 500 : firstInnerWidth}px`}
             max-width='500px'
             title='strava-goody'
             frameBorder='0'
             allowtransparency='true'
             scrolling='no' className="lazyload"
             src='https://www.strava.com/clubs/133679/latest-rides/6fb9f07a916b1575b51b8919e05ee3b1bc61bf0b?show_rides=true'
             style={{padding:'10px'}}>
           </iframe>
           <iframe src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFilOzTri%2F&tabs=timeline&width=${secondInnerWidth > 500 ? 480 : secondInnerWidth}&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1060560170622193`}
            loading="lazy"
            height='470px'
            width={`${firstInnerWidth > 500 ? 500 : firstInnerWidth}px`}
            max-width='500px'
            style={{
              border: 'none',
              overflow: 'hidden',
              padding:'10px'
            }}
            scrolling="no"
            frameBorder="0"
            allow-fullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
           </iframe>
         </div>
       </div>
      </div>
    )
  }

  render() {
    const {
      image,
      title,
      subheading,
      subheading2,
      subheading3,
      mainpitch,
      intro
    } = this.props;

    return (
      <div>
        <PreviewCompatibleBackroundImage imageInfo={image}>
          { this.headerContents(title, subheading, subheading2, subheading3) }
        </PreviewCompatibleBackroundImage>
        <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="content">
                    <div className="content">
                      <div className="tile">
                        <h1 className="title">{mainpitch.title}</h1>
                      </div>
                      <div className="tile">
                        <h3 className="subtitle">{mainpitch.description}</h3>
                      </div>
                    </div>
                    <Features gridItems={intro.blurbs} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  subheading2: PropTypes.string,
  subheading3: PropTypes.string,
  mainpitch: PropTypes.object,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        subheading2={frontmatter.subheading2}
        subheading3={frontmatter.subheading3}
        mainpitch={frontmatter.mainpitch}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        subheading
        subheading2
        subheading3
        mainpitch {
          title
          description
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 440, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
        }
      }
    }
  }
`
